body {
  margin: auto;
  width: 100%;
  box-shadow: 0 4px 8px 0 black;
  max-width: 768px;
  // height: 100%;
  // max-height: 1024px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // overscroll-behavior-y: contain;
  ::-webkit-scrollbar {
    width: 8px;
    // height: 5px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #5c438a1e;
    border-radius: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #009544;
    border-radius: 6px;
    transition: 0.4s ease;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #009544;
    transition: 0.4s ease;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Vista";
  src: local("Vista"), url("./assets/fonts/Vista.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "VistaBold";
  src: local("VistaBold"),
    url("./assets/fonts/VistaBold.ttf") format("truetype");
  font-weight: normal;
}
